import { groupCallSourceURLMap } from '@17live/app/components/GroupCall/constants';

import { SourceURL } from './types';

export const sourceURLMap: { [key: string]: SourceURL } = Object.freeze({
  '^/animal$': 'Animal',
  '^/artisan$': 'Artisan',
  '^/avatar/info1$': 'Avatar_infopage',
  '^/avatar/info2$': 'Avatar_rules',
  '^/chatting$': 'Chat',
  '^/clips/featured': 'Clips_featured',
  '^/clips/following': 'Clips_following',
  '^/clip/': 'Clip',
  '^/cooking$': 'Cooking',
  '^/creative$': 'Creative',
  '^/dancing$': 'Dancing',
  '^/fortune-telling$': 'Fortune_telling',
  '^/post/': 'Post',
  '^/sports$': 'Sports',
  '^/suggested$': 'Hotpage',
  '^/vliver$': 'Vliver',
  '^/music$': 'Music',
  '^/male$': 'Male',
  '^/female$': 'Female',
  '^/vip$': 'VIPrecommend',
  '^/gaming/.+': 'Gaming_indiviual',
  '^/gaming$': 'Gaming',
  '^/vod': 'Vod',
  '^/leaderboard': 'Leaderboard',
  '/following$': 'Myfollow',
  '^/recommended$': 'Recommend',
  '^/event': 'Event',
  '^/profile/.+/clip': 'Clip',
  '^/profile/.+/post': 'Post',
  '^/profile/.+/video': 'Profile_ArchiveVideo.player',
  '^/profile/': 'NameCard',
  '^/point$': 'Mycoins',
  '^/revenue/': 'Myrevenue',
  '^/live/': 'Livestream_LiveScreen',
  '^/purchase/.+/vip_super': 'IAP_VIP',
  '^/purchase/.+/vip_baby': 'IAP_VIP_baby',
  '^/purchase': 'IAP',
  '^/army': 'Army',
  '^/settings/live': 'Stream_setting',
  '^/admin/analysis': 'Stream_analysis',
  '^/streaming/guide': '17school',
  '^/groupcall': groupCallSourceURLMap.groupCall,
  '^/shopping': 'Live_commerce',
  '^/$': 'Homepage',
  '^/v-live-character$': 'v-live-character',
  '^/analytics/audience/topgifter': 'Analytics_audience_topviewer',
  '^/analytics/audience/subscriber': 'Analytics_audience_subscriber',
  '^/analytics/overview': 'Analytics_overview',
  '^/analytics/audience': 'Analytics_audience',
});

export const sourceURLMapEntries = Object.entries(sourceURLMap);

export const userType = {
  USER: 'USER',
  GUEST: 'GUEST',
};

export const genericType = {
  MOBILE: 'MOBILE',
  WEB: 'WEB',
};

export const customDimensionMap = {
  contentID: 'dimension1',
  contentType: 'dimension2',
  genericText: 'dimension12',
  traceID: 'dimension13',
  appVersion: 'dimension15',
  deviceID: 'dimension16',
  timestamp: 'dimension17',
  componentID: 'dimension21',
  componentType: 'dimension22',
  advertisingID: 'dimension23',
  sourceURL: 'dimension24',
  user: 'dimension25',
  actionType: 'dimension27',
  timezone: 'dimension34',
};
