/**
 *
 * ErrorBoundary
 *
 * This is a wrapper component which just return the children when nothing goes wrong,
 * and when it did go wrong, it will display a fallback error component and log to external service.
 *
 */
import React, { useCallback } from 'react';
import { QueryErrorResetBoundary } from 'react-query';

import ErrorBlock from '@/components/ErrorBlock';

import Logger from '@17live/app/services/Logger';

import * as Sentry from '@sentry/react';

const LOGGER_LABEL: string = 'ErrorBoundary';

/**
 * @see FallbackRender node_modules/@sentry/react/dist/errorboundary.d.ts
 */
interface FallbackProps {
  error: Error;
  componentStack: string | null;
  eventId: string | null;
  resetError(): void;
}

const ErrorBoundary: React.FC<{
  fallback?: ({
    error,
    componentStack,
    resetError,
  }: FallbackProps) => React.ReactElement;
}> = ({ fallback, children }) => {
  const handleOnError = useCallback((error: Error, componentStack: string) => {
    const errorLevel =
      process.env.DEPLOY_ENV === 'production' ? 'error' : 'warn';

    Logger[errorLevel](LOGGER_LABEL, error.toString(), { componentStack });
  }, []);

  const handelFallback = useCallback(
    props => {
      return (fallback && fallback(props)) || <ErrorBlock />;
    },
    [fallback]
  );

  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <Sentry.ErrorBoundary
          fallback={handelFallback}
          onError={handleOnError}
          onReset={reset}
        >
          {children}
        </Sentry.ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
};

export default ErrorBoundary;
